@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("assets/fonts/flaticon/css/flaticon.css");
@import url("assets/fonts/fontawesome5/css/all.min.css");
@import url("assets/fonts/line-awesome/css/line-awesome.css");
@import url(assets/fonts/metronic/css/styles.css);
@import url("../node_modules/perfect-scrollbar/css/perfect-scrollbar.css");
body,
html {
  height: 100%; }

@media (min-width: 1024px) {
  .m-brand {
    width: 256px; } }

.m-aside-left {
  width: 256px; }

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important; }

@media (min-width: 1025px) {
  .m-aside-left--fixed .m-body {
    padding-left: 256px; } }

.m-splash-screen {
  background-color: #f2f3f8;
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 0; }

.ng-select .ng-select-container {
  border-color: #ebedf2;
  color: #575962; }

.ng-select .ng-dropdown-panel {
  border-color: #ebedf2; }

.ng-select.custom-height .ng-select-container {
  min-height: 40px;
  max-height: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 1.5;
  border-color: #ebedf2;
  color: #575962; }

.ng-select.custom-height .ng-dropdown-panel {
  border-color: #ebedf2; }

.ng-select.custom-height .ng-select.ng-select-single
.ng-select-container
.ng-value-container
.ng-input {
  top: 50%;
  transform: translateY(-50%); }

.ng-select:focus-within .ng-select-container {
  border-color: #716aca;
  box-shadow: none; }

.ng-select:focus-within .ng-dropdown-panel {
  border-color: #716aca;
  box-shadow: none;
  padding-right: 1px;
  padding-left: 1px; }

.ng-select.custom-height:focus-within .ng-select-container {
  border-color: #716aca;
  box-shadow: none; }

.ng-select.custom-height:focus-within .ng-dropdown-panel {
  border-color: #716aca;
  box-shadow: none;
  padding-right: 1px;
  padding-left: 1px; }

th.desc::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  padding-left: 2px;
  content: "\f0dd"; }

th.asc::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  padding-left: 2px;
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\f0de"; }

th[appsortable] {
  cursor: pointer; }

td.minimum,
th.minimum {
  white-space: nowrap;
  width: 1px; }

.ql-video {
  width: 480px;
  height: 320px; }

form .form-control-feedback {
  margin-top: 0.2rem;
  font-size: 0.85rem; }

.m-brand {
  background: #ffffff !important;
  border-bottom: 1px solid #eaedf1; }

.m-page {
  height: 100vh; }

.m-header-head {
  background-color: #f2f3f8; }

.m-aside-left.m-aside-left--skin-dark {
  background: #ffffff; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--expanded,
.m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item,
.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--expanded {
  background: #ffffff;
  color: #25252a; }
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--expanded .m-menu__link .m-menu__link-text,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--expanded .m-menu__link .m-menu__link-icon,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--expanded .m-menu__link .m-menu__ver-arrow,
  .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__link .m-menu__link-text,
  .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__link .m-menu__link-icon,
  .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item .m-menu__link .m-menu__ver-arrow,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--expanded .m-menu__link .m-menu__link-text,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--expanded .m-menu__link .m-menu__link-icon,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--expanded .m-menu__link .m-menu__ver-arrow {
    color: #25252a !important; }

.m-aside-left--minimize .m-aside-menu.m-aside-menu--skin-dark .m-menu__nav > .m-menu__item.m-menu__item--expanded > .m-menu__link > .m-menu__link-icon {
  color: #25252a !important; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
li.m-menu__item:last-child,
.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
li.m-menu__item:last-child {
  border-bottom: none; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--active,
.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--active {
  background: #4f77ff; }
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--active .m-menu__link .m-menu__link-text,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.m-menu__item--active .m-menu__link .m-menu__link-icon,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--active .m-menu__link .m-menu__link-text,
  .m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
.m-menu__submenu
.m-menu__item.m-menu__item--active .m-menu__link .m-menu__link-icon {
    color: #fff !important; }

.m--bg-mlm-red {
  background-color: #ef4850 !important; }

.m--bg-mlm-purple {
  background-color: #7350a2 !important; }

.table-tworld.align-middle td,
.table-tworld.align-middle th {
  vertical-align: middle; }

.table-tworld.table-sm td,
.table-tworld.table-sm th {
  padding: 0.8rem; }

.tworld-text-primary {
  color: #4a5073 !important; }

.table-tworld th,
.table-tworld thead th {
  border-bottom: 1px solid white;
  color: white !important; }

.table-tworld td,
.table-tworld tbody + tbody {
  border-color: #e6e7ef; }

.table-tworld,
.table-tworld > th,
.table-tworld > td {
  background-color: #734a5a;
  color: #3e4671 !important; }

.table-tworld > tbody tr {
  background-color: white; }

.table-tworld > tbody tr:hover {
  background-color: #ccc; }

.table-hover tbody tr:hover {
  border: none;
  background-color: #f2f1ff; }

.badge-package-a {
  color: #fff;
  background-color: #3a90cd; }

.badge-package-b {
  color: #fff;
  background-color: #849a26; }

.badge-package-c {
  color: #fff;
  background-color: #ef4850; }

.badge-package-expired {
  color: #fff;
  background-color: goldenrod; }

.badge-package-d {
  color: #fff;
  background-color: #e133ef; }

.badge-package-e {
  color: #fff;
  background-color: #65ef31; }

.status_user_app_block {
  color: #fff;
  background-color: #000000; }

.badge-tworld-secondary {
  color: #ffffff;
  background-color: #289235; }

.badge-tworld-request {
  color: #ffffff;
  background-color: #808080; }

.btn.btn-tworld-important {
  background-color: #ef4850;
  color: #fff !important;
  border-color: #ef4850; }

.btn.btn-tworld-important:hover {
  background-color: #ef4850; }

.btn.btn-tworld-info {
  background-color: #4a5073;
  border-color: #4a5073;
  color: #fff; }

.btn-tworld-secondary {
  background: #716da0;
  border-color: #ebedf2;
  color: #fff; }

.btn-tworld-secondary:hover {
  opacity: 0.75;
  color: #fff; }

.btn.btn-tworld-create {
  background-color: #2b88c9;
  border-color: #2b88c9;
  color: #fff; }

.btn.btn-tworld-create:hover {
  opacity: 0.75;
  color: #fff; }

.m-login__btn--primary {
  color: white;
  border-color: #f4516c !important;
  background-color: #f4516c !important; }

.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__form
.m-login__form-action
.m-login__btn.m-login__btn--primary:focus,
.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__form
.m-login__form-action
.m-login__btn.m-login__btn--primary:disabled {
  border-color: gray;
  color: white; }

.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__head
.m-login__title {
  color: #694eb9; }

.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__form
.form-control {
  background: #694eb9; }

.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__form
.m-login__form-sub
.m-checkbox,
.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__form
.m-login__form-sub
.m-link,
.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__head
.m-login__desc {
  color: #6a94c4; }

.m-login.m-login--2.m-login-2--skin-1
.m-login__container
.m-login__form
.m-login__form-sub
.m-link:hover {
  color: #694eb9; }

.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__logo {
  margin: 0 auto 2rem auto; }

.m-login .m-checkbox.m-checkbox--light > span:after {
  border: 1px solid #694eb9; }

.m-login .m-checkbox.m-checkbox--light > input:checked ~ span {
  border: 1px solid #694eb9; }

.m-login a,
.m-login a:hover {
  text-decoration: underline;
  text-decoration-color: #694eb9; }

.m-widget25 {
  overflow: hidden;
  text-overflow: ellipsis; }
  .m-widget25 .m-widget25__price {
    font-size: 3.2rem;
    font-weight: bold;
    color: white; }

.m-form .m-form__section {
  color: #102bbb !important;
  margin: 20px 0 20px 0;
  font-size: 1.5rem; }

.tworld-form-header {
  color: #102bbb !important;
  margin: 0 0 7px 0;
  font-size: 22px; }

.form-control-plaintext.balance {
  color: #ffffff;
  background-color: #ef4850;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  margin-bottom: 0;
  line-height: 1.25;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 1rem; }

.m-portlet .m-portlet__head .m-portlet__head-text {
  color: #102bbb; }

.page-item.active .page-link {
  color: #fff;
  background-color: #bdbdbd;
  border-color: #bdbdbd; }

@media screen and (max-width: 720px) {
  ul.pagination {
    justify-content: center; } }

.intl-tel-input {
  width: 100%; }

.m-login__form .intl-tel-input.allow-dropdown input,
.m-login__form .intl-tel-input.allow-dropdown input[type="text"] {
  margin-left: 0;
  padding: 1.5rem 6px 1.5rem 52px !important; }

.m-login__form .intl-tel-input {
  margin-top: 1.5rem;
  width: 100%; }
  .m-login__form .intl-tel-input .form-control {
    padding: 0 !important; }

.m-login__form .user .intl-tel-input {
  margin-top: 0; }

.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__form {
  margin: 2rem auto; }

.mg0 {
  margin: 0 !important; }

.mgt-0 {
  margin-top: 0px; }

.mgt-5 {
  margin-top: 5px; }

.mgt-10 {
  margin-top: 10px; }

.mgt-15 {
  margin-top: 15px; }

.mgt-20 {
  margin-top: 20px; }

.mgt-25 {
  margin-top: 25px; }

.mgt-30 {
  margin-top: 30px; }

.mgr-0 {
  margin-right: 0px; }

.mgr-5 {
  margin-right: 5px; }

.mgr-10 {
  margin-right: 10px; }

.mgr-15 {
  margin-right: 15px; }

.mgr-20 {
  margin-right: 20px; }

.mgr-25 {
  margin-right: 25px; }

.mgr-30 {
  margin-right: 30px; }

.mgb-0 {
  margin-bottom: 0px; }

.mgb-5 {
  margin-bottom: 5px; }

.mgb-10 {
  margin-bottom: 10px; }

.mgb-15 {
  margin-bottom: 15px; }

.mgb-20 {
  margin-bottom: 20px; }

.mgb-25 {
  margin-bottom: 25px; }

.mgb-30 {
  margin-bottom: 30px; }

.mgl-0 {
  margin-left: 0px; }

.mgl-5 {
  margin-left: 5px; }

.mgl-10 {
  margin-left: 10px; }

.mgl-15 {
  margin-left: 15px; }

.mgl-20 {
  margin-left: 20px; }

.mgl-25 {
  margin-left: 25px; }

.mgl-30 {
  margin-left: 30px; }

.pdl-0 {
  padding-left: 0px; }

.pdl-5 {
  padding-left: 5px; }

.pdl-10 {
  padding-left: 10px; }

.pdl-15 {
  padding-left: 15px; }

.pdl-20 {
  padding-left: 20px; }

.pdl-25 {
  padding-left: 25px; }

.pdl-30 {
  padding-left: 30px; }

.pdr-0 {
  padding-right: 0px; }

.pdr-5 {
  padding-right: 5px; }

.pdr-10 {
  padding-right: 10px; }

.pdr-15 {
  padding-right: 15px; }

.pdr-20 {
  padding-right: 20px; }

.pdr-25 {
  padding-right: 25px; }

.pdr-30 {
  padding-right: 30px; }

.pdt-0 {
  padding-top: 0px; }

.pdt-5 {
  padding-top: 5px; }

.pdt-10 {
  padding-top: 10px; }

.pdt-15 {
  padding-top: 15px; }

.pdt-20 {
  padding-top: 20px; }

.pdt-25 {
  padding-top: 25px; }

.pdt-30 {
  padding-top: 30px; }

.pdb-0 {
  padding-bottom: 0px; }

.pdb-5 {
  padding-bottom: 5px; }

.pdb-10 {
  padding-bottom: 10px; }

.pdb-15 {
  padding-bottom: 15px; }

.pdb-20 {
  padding-bottom: 20px; }

.pdb-25 {
  padding-bottom: 25px; }

.pdb-30 {
  padding-bottom: 30px; }

.pd0 {
  padding: 0 !important; }

.back-to-top {
  position: fixed;
  bottom: 0px;
  right: 100px;
  z-index: 9;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #d2a350;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden; }

.back-to-top i {
  font-size: 20px;
  line-height: 40px;
  color: #fff; }

.back-to-top:hover {
  opacity: 1;
  transition: all 0.2s;
  background-color: #d2a350; }

.back-to-top.active {
  bottom: 40px;
  opacity: 1;
  visibility: visible; }

.visible-hidden {
  visibility: hidden;
  height: 0px;
  width: 0px;
  position: absolute; }

body {
  font-size: 16px;
  line-height: 1.6;
  font-family: "Noto Sans JP", sans-serif;
  color: #3b4855;
  font-weight: 400;
  overflow-x: hidden; }
  body a {
    color: #1f7dc0;
    transition: all 0.2s; }
  @media screen and (max-width: 1440px) {
    body {
      font-size: 14px; } }

.m-aside-left--on {
  overflow: hidden; }

.hover {
  -webkit-transition: all 0.2s ease-in-out;
  -khtml-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.hover:hover {
  -webkit-transition: all 0.2s ease-in-out;
  -khtml-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.login-wrapper {
  min-height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center; }

@media screen and (max-width: 767px) {
  .login-wrapper {
    align-items: flex-start;
    padding-top: 20px; } }

a,
a:focus,
a:hover {
  color: #4f77ff; }

.btn-primary {
  background-color: #4f77ff;
  border-color: #4f77ff; }

.btn-primary.active {
  background-color: #486ff2;
  border-color: #486ff2; }

.btn-primary:active {
  background-color: #486ff2;
  border-color: #486ff2; }

.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: #486ff2;
  border-color: #486ff2; }

.btn-primary:focus,
.btn-primary:hover {
  background-color: #486ff2;
  border-color: #486ff2; }

.brand-logo {
  margin-top: 30px; }

.brand-logo img {
  max-height: 80px; }

.styly-login {
  background-color: #fff;
  border-radius: 5px;
  margin: 25px auto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25); }

.styly-login .styly-panel {
  padding: 40px;
  transition: all 0.5s ease; }

@media screen and (max-width: 767px) {
  .styly-login .styly-panel {
    padding: 30px 20px; } }

.styly-panel .styly-heading {
  margin-bottom: 30px; }

.styly-login .auth-title {
  font-weight: 700;
  margin-top: 0; }

.styly-login .social-buttons a {
  color: #fff; }

.styly-login .social-buttons a:hover {
  color: #fff;
  opacity: 0.9; }

.styly-login .social-buttons .btn-facebook {
  background: #3b5998; }

.styly-login .social-buttons .btn-twitter {
  background: #00aced; }

.styly-login .social-buttons .btn-google {
  background: #c32f10; }

.styly-login .loginOr {
  position: relative;
  font-size: 1.5em;
  color: #aaa;
  margin-top: 1em;
  margin-bottom: 0.3em;
  padding-top: 0.5em;
  padding-bottom: 0.5em; }

.styly-login .loginOr .hrOr {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.styly-login .loginOr .spanOr {
  display: block;
  position: absolute;
  left: 50%;
  top: -0.75em;
  margin-left: -1.5em;
  background-color: #fff;
  width: 3em;
  text-align: center; }

.panel-login .remember-row {
  margin-bottom: 10px; }

.panel-login .remember-row label {
  font-weight: 400;
  position: relative;
  cursor: pointer;
  color: #666;
  padding-left: 24px; }

.styly-login .forgotPwd {
  text-align: right;
  margin-top: 10px; }

.panel-forgot .form-control,
.panel-login .form-control,
.panel-signup .form-control {
  color: #333;
  font-size: 16px;
  height: 50px;
  padding: 12px 0;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.panel-forgot .form-control:focus + .focus-input:before,
.panel-login .form-control:focus + .focus-input:before,
.panel-signup .form-control:focus + .focus-input:before {
  width: 100%; }

.wrap-input {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9; }

.focus-input {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none; }

.focus-input:before {
  display: block;
  position: absolute;
  left: 0;
  background-color: #4f77ff;
  content: "";
  width: 0;
  height: 2px;
  bottom: -2px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s; }

.pwdMask {
  position: relative; }

.pwdMask .pwd-toggle {
  color: #ccc;
  position: absolute;
  z-index: 2; }

.panel-login .pwdMask .pwd-toggle {
  top: 20px;
  right: 15px; }

.panel-signup .pwdMask .pwd-toggle {
  top: 15px;
  right: 15px; }

.pwdMask .pwd-toggle:focus,
.pwdMask .pwd-toggle:hover {
  cursor: pointer; }

.pwdMask .pwd-toggle.fa-eye,
.pwdMask .pwd-toggle:focus,
.pwdMask .pwd-toggle:hover {
  color: #4f77ff; }

.panel-signup .term-policy a {
  text-decoration: underline; }

.checkbox input[type="checkbox"] {
  position: absolute;
  right: 9000px;
  opacity: 0;
  z-index: 1;
  cursor: pointer; }

.checkbox input[type="checkbox"] + .label-text:after {
  font-size: 11px;
  display: inline-block;
  width: 17px;
  height: 17px;
  padding: 2px;
  margin-left: 0;
  position: absolute;
  top: 2px;
  left: 0; }

.checkbox input[type="checkbox"] + .label-text:before {
  font-size: 11px;
  display: inline-block;
  width: 17px;
  height: 17px;
  padding: 2px;
  margin-left: 0;
  position: absolute;
  top: 2px;
  left: 0;
  content: "";
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  cursor: pointer;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.checkbox input[type="checkbox"] + .label-text:after {
  background-color: #4f77ff;
  color: #fff;
  border-radius: 2px;
  border-color: #4f77ff;
  line-height: 1.4; }

.checkbox input[type="checkbox"]:focus + .label-text:before {
  outline: dotted thin;
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px; }

.checkbox input[type="checkbox"]:checked + .label-text:after {
  font-family: FontAwesome;
  content: ""; }

@media (max-width: 320px) {
  .styly-heading p {
    font-size: 14px; }
  .remember-row .col-sm-6 {
    width: 100%; }
  .styly-login .forgotPwd {
    text-align: left;
    margin-top: 0; } }

.stepsbar {
  width: 100%; }

.stepsbar ol {
  padding: 0;
  margin: 0;
  height: 100px;
  list-style: none;
  line-height: 1.2;
  font-size: 14px;
  counter-reset: li;
  animation: chase 1s 1 forwards; }

.stepsbar li {
  float: left;
  display: inline;
  position: relative;
  counter-increment: li;
  width: calc(100% / 4);
  text-align: center;
  color: #ccc;
  transition: all 0.5s ease; }

.stepsbar li:before {
  content: counter(li);
  position: relative;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0 auto 14px;
  border-radius: 100%;
  text-align: center;
  z-index: 2;
  border: 1px solid #fff;
  box-shadow: 0 0 2px 2px #fff;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-repeat: repeat;
  background-size: 20px 20px;
  font-size: 14px; }

.stepsbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  display: block;
  background: #ccc;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 20px 20px; }

.stepsbar li:first-child.active:after {
  left: 50%; }

.stepsbar li:last-child:after {
  display: none; }

.stepsbar li a {
  color: #ccc;
  text-decoration: none;
  position: absolute;
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  top: 0;
  left: calc(50% - 40px);
  z-index: 999;
  align-items: flex-end; }

.stepsbar li.done {
  color: #8bc53f; }

.stepsbar li.done a {
  color: #8bc53f; }

.stepsbar li.done:after {
  background-color: #8bc53f;
  animation: progress 0.3s linear 10; }

.stepsbar li.done:before {
  background-color: #8bc53f;
  border-color: #70a130;
  color: white; }

.stepsbar li.active a {
  color: #4f77ff; }

.stepsbar li.active:before {
  background-color: #4f77ff;
  color: white;
  box-shadow: 0 0 0 2px #4f77ff;
  animation: progress 0.5s linear infinite;
  animation-delay: 3s; }

.btn-success {
  background-color: #8bc53f;
  border-color: #8bc53f; }

.styly-register {
  background-color: #fff;
  border-radius: 5px;
  margin: 25px auto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25); }

.styly-register .styly-panel {
  padding: 40px;
  transition: all 0.5s ease; }

@media screen and (max-width: 767px) {
  .styly-register .styly-panel {
    padding: 30px 20px; } }

.styly-register .form-register legend {
  padding: 6px 8px 6px 15px;
  border-left: 3px solid #4f77ff;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 40px;
  background: #f5f5f5; }

.styly-register .form-register .form-group label {
  font-weight: 600;
  font-size: 14px; }

.styly-register .form-register .form-group label.isRequired:after {
  display: inline-block;
  background-color: #dc3545;
  color: #fff;
  padding: 1px 3px;
  font-size: 11px;
  content: attr(data-required);
  margin-left: 5px; }

.form-label .form-group label.isRequired:after {
  display: inline-block;
  background-color: #dc3545;
  color: #fff;
  padding: 1px 3px;
  font-size: 11px;
  content: attr(data-required);
  margin-left: 5px; }

.styly-register .date.input-group > .input-group-append > .btn {
  border-color: #ced4da; }

.styly-register .button-group .btn {
  min-width: 150px;
  margin: 5px;
  font-size: 1rem; }

.styly-panel-left {
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }

.styly-panel-left .styly-login {
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  margin: 0; }

.styly-panel-left .styly-login .styly-panel {
  padding: 15px 30px; }

.styly-panel-right {
  background-color: rgba(45, 56, 94, 0.6);
  color: #fff;
  height: 100vh; }

.styly-panel-right .hero-heading {
  display: table;
  height: 100vh;
  width: 100%;
  text-align: center; }

.styly-panel-right .hero-heading .headline {
  display: table-cell;
  vertical-align: middle; }

.styly-panel-right .hero-heading .headline h3 {
  font-size: 3rem;
  font-weight: 700; }

.nav-tabs {
  margin-bottom: 30px; }

.nav-tabs .nav-link {
  margin-top: 1px;
  padding: 15px 30px;
  font-weight: bold; }

.nav-tabs .nav-link.active {
  margin-top: 0;
  border-top: 2px solid #4f77ff; }

.sp {
  display: none; }

.pc {
  display: block; }

@media screen and (max-width: 991px) {
  .sp {
    display: block; }
  .pc {
    display: none; }
  .styly-panel-left {
    order: 2;
    height: auto; }
  .styly-panel-right {
    order: 1; }
  .styly-panel-right .hero-heading .headline h3 {
    font-size: 2rem; } }

.dashboard-wrapper {
  min-height: 100vh;
  background-color: #f1f1f9; }

.dashboard-content {
  padding: 30px;
  min-height: 100vh;
  width: calc(100% - 250px);
  margin-left: 250px;
  transition: 0.3s; }

.dashboard-content.active {
  margin-left: 60px;
  width: calc(100% - 60px);
  transition: 0.3s; }

@media screen and (max-width: 991px) {
  .dashboard-content {
    margin-left: 60px;
    width: calc(100% - 60px);
    transition: 0.3s; }
  .dashboard-content.active {
    width: calc(100% - 250px);
    margin-left: 250px; } }

.sidebar-left {
  background: #ffffff;
  border-right: 1px solid #e5e5e5;
  color: #8a98ac;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  transition: all 0.2s;
  z-index: 1000;
  -webkit-box-shadow: 0 8px 24px #e5e4e6;
  box-shadow: 0 8px 24px #e5e4e6;
  width: 250px; }

.sidebar-left .side-header {
  height: 60px;
  padding: 10px;
  border-bottom: 1px solid #eaedf1;
  position: relative; }

.form-label .form-group label {
  font-size: 14px; }

.title {
  padding: 6px 8px 6px 15px;
  border-left: 3px solid #4f77ff;
  font-weight: bold;
  font-size: 20px;
  background: #f5f5f5; }

.sidebar-left .side-header img {
  max-height: 40px;
  max-width: 100%; }

.sidebar-left .side-header .navbar-toggler span {
  transition: 0.3s; }

.sidebar-left .side-header .navbar-toggler {
  width: 46px;
  height: 46px;
  margin: 0;
  background-color: #fff;
  background-image: none;
  border-radius: 0;
  outline: none;
  border: none;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.3s; }

.sidebar-left .side-header .navbar-toggler:focus {
  outline: none; }

.sidebar-left .side-header .navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  transition: 0.3s;
  background: #1f7cc4; }

.sidebar-left .side-header .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px; }

.sidebar-left .side-body {
  height: calc(100vh - 61px);
  overflow: auto; }

.sidebar-left .side-body .main-menu ul {
  padding: 10px 0;
  margin: 0; }

.sidebar-left .side-body .main-menu ul li {
  list-style: none; }

.sidebar-left .side-body .main-menu ul li a {
  position: relative;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #25252a;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear; }

.sidebar-left .side-body .main-menu ul li a:hover {
  color: #4f77ff; }

.sidebar-left .side-body .main-menu ul li a .nav-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 25px;
  text-align: center;
  vertical-align: middle; }

.sidebar-left .side-body .main-menu ul li a .nav-icon i {
  font-size: 18px; }

.sidebar-left .side-body .main-menu ul li a .nav-text {
  position: relative;
  display: flex;
  vertical-align: middle;
  width: 215px; }

.sidebar-left .side-body .main-menu ul li.active > a {
  color: #4f77ff; }

.sidebar-left .side-body .main-menu ul li ul {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  padding: 0;
  margin-left: 27px;
  border-left: 1px solid #d0d0d0; }

.sidebar-left .side-body .main-menu ul li ul li a {
  padding-left: 5px; }

.sidebar-left .side-body .main-menu ul li.active ul {
  max-height: 100%; }

.sidebar-left .side-body .main-menu ul.logout {
  position: absolute;
  left: 0;
  bottom: 0; }

.sidebar-left.active {
  width: 60px;
  transition: all 0.2s; }

.sidebar-left.active .side-header .navbar-toggler {
  right: 7px; }

.sidebar-left.active .side-body .main-menu ul li a .nav-text {
  display: none; }

.sidebar-left.active .side-body .main-menu ul li ul {
  display: none; }

@media screen and (max-width: 991px) {
  .sidebar-left {
    width: 60px;
    transition: all 0.2s; }
  .sidebar-left .side-header .navbar-toggler {
    right: 7px; }
  .sidebar-left .side-body .main-menu ul li a .nav-text {
    display: none; }
  .sidebar-left .side-body .main-menu ul li ul {
    display: none; }
  .sidebar-left.active {
    width: 250px; }
  .sidebar-left.active .side-header .navbar-toggler {
    right: 7px; }
  .sidebar-left.active .side-body .main-menu ul li a .nav-text {
    display: block; }
  .sidebar-left.active .side-body .main-menu ul li ul {
    display: block; } }

.header {
  background-color: #000000;
  padding: 10px 0; }
  .header nav {
    display: flex;
    align-items: center; }

.header .logo-header img {
  max-height: 50px; }

.header ul {
  margin-bottom: 0; }

button:focus {
  outline: none; }

img {
  max-width: 100%; }

.modal-login .modal-dialog {
  width: 100%;
  max-width: 500px; }

.modal-login .close {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 30px;
  z-index: 99; }

.modal-login .styly-login {
  padding: 0;
  border-radius: 0;
  box-shadow: none; }

.modal-login .styly-login .styly-panel {
  padding: 20px 20px 0 20px; }

.modal-login.show {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.section {
  padding: 50px 0; }

.section-intro .pict-item {
  margin-top: 20px;
  padding: 0 10px; }

.section-intro .pict-item .pict-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px; }

.section-intro .pict-item img {
  height: 250px;
  margin-bottom: 20px; }

.section-intro .pict-item .pict-introduction {
  font-size: 14px; }

.section-partner {
  background-color: #f0faff; }

.correspondence-container {
  display: inline-block;
  text-align: center;
  width: 100%; }

.correspondence-container .correspondence-logo {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  text-align: center; }

.correspondence-container .correspondence-logo a {
  text-decoration: none;
  color: #000; }

.correspondence-container .correspondence-logo a h3 {
  font-size: 16px; }

.correspondence-container .correspondence-logo img {
  width: 70px;
  margin: 0 auto 5px auto; }

.footer {
  background-color: #141c26; }

.footer .footer-top {
  background-color: #243346;
  padding: 30px 0; }

.footer .footer-content {
  color: #fff;
  padding: 50px 20px 0; }

.footer .footer-content .footer-info {
  padding: 20px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center; }

.footer .footer-content .footer-info .logo-footer img {
  max-height: 50px;
  margin-right: 15px; }

.footer .footer-content .footer-info .logo-footer a {
  text-decoration: none;
  display: inline-block; }

.footer .footer-content .footer-info .info-footer {
  text-align: left;
  display: inline-block; }

.footer .footer-content .footer-info .info-footer h5 {
  font-size: 18px;
  font-weight: normal; }

.footer .footer-content .footer-info .info-footer ul li {
  margin: 5px 0;
  word-break: break-word; }

.footer .footer-content .copyright {
  padding-top: 20px;
  font-size: 18px; }

.footer .footer-content .copyright-img {
  margin-top: 10px; }
  .footer .footer-content .copyright-img img {
    max-width: 100%;
    max-height: 20px; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5; }

@media screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2rem; }
  h2,
  .h2 {
    font-size: 1.5rem; }
  h3,
  .h3 {
    font-size: 1.5rem; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  border-radius: 3px;
  position: relative;
  right: 0;
  padding: 0;
  color: #fff;
  background: none; }

.breadcrumb .breadcrumb-item {
  font-size: 13px; }

.breadcrumb .breadcrumb-item a {
  color: #444444; }

.breadcrumb .breadcrumb-item a:hover {
  color: #4f77ff;
  text-decoration: none; }

.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #444444;
  content: "/"; }

.breadcrumb .breadcrumb-item.active {
  color: #4f77ff; }

.page-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.5rem;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  min-height: 50px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 8px 24px #e5e4e6;
  border: 1px solid transparent;
  border-radius: 5px; }

.page-header .page-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin-bottom: 0.2rem; }

.page-header .breadcrumb {
  font-size: 12px;
  padding-left: 0 !important; }

@media screen and (max-width: 991px) {
  .page-header {
    display: block; } }

.card {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  border: none;
  background-clip: border-box;
  border-radius: 5px;
  box-shadow: 0 8px 24px #e5e4e6; }

.card .card-title {
  font-size: 1rem;
  font-weight: bold; }

.card .card-header {
  background: none;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dee2e6; }

.card .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  padding: 1.5rem 1.5rem;
  position: relative; }

.table-list-account {
  word-break: keep-all;
  margin-bottom: 0; }

.table-list-account thead {
  background-color: #ececec; }

.table-list-account td,
.table-list-account th {
  padding: 0.5rem 0.75rem; }

.table-list-account .btn-sm {
  font-size: 13px; }

.table-list-account .action-col {
  min-width: 120px;
  width: 120px;
  max-width: 120px; }

@media (max-width: 991px) {
  .dashboard-content {
    padding: 1rem 15px; }
  .page-header,
  .card {
    margin-bottom: 1rem; } }

.table-responsive {
  margin-bottom: 16px; }

.form-item-row.row {
  padding-left: 0; }

.with-button {
  width: 120px; }

.padding-button-top {
  padding-top: 20px; }

.label-danger {
  display: inline-block;
  background-color: #dc3545;
  color: #fff;
  padding: 1px 3px;
  font-size: 13px;
  border-radius: 0.35rem; }

.label-success {
  display: inline-block;
  background-color: #1fab8f;
  color: #fff;
  padding: 1px 3px;
  font-size: 13px;
  border-radius: 0.35rem; }

.label-primary {
  display: inline-block;
  background-color: #337ab7;
  color: #fff;
  padding: 1px 3px;
  font-size: 13px;
  border-radius: 0.25rem; }

.label-warning {
  display: inline-block;
  background-color: #f0ad4e;
  color: #fff;
  padding: 1px 3px;
  font-size: 13px;
  border-radius: 0.35rem; }

.label-error {
  font-size: 80%; }

.m-input-icon.m-input-icon--left .form-control {
  padding-left: 3.3rem !important; }

.m-brand .m-brand__tools .m-brand__icon span:after,
.m-brand .m-brand__tools .m-brand__icon span:before {
  width: 100% !important; }

.m-aside-left--minimize .m-brand .m-brand__tools .m-brand__icon span:after {
  width: 75% !important; }

.m-aside-left--minimize .m-brand .m-brand__tools .m-brand__icon span:before {
  width: 50% !important; }

.m-grid__item.m-header {
  height: 90px !important; }

.m-aside-left--minimize--hover
.m-stack__item.m-stack__item--middle.m-brand__logo.text-center {
  z-index: 99999; }
  .m-aside-left--minimize--hover
.m-stack__item.m-stack__item--middle.m-brand__logo.text-center img {
    height: 100%; }

ngb-pagination > .pagination .page-item:nth-child(1) a {
  color: transparent;
  position: relative; }

ngb-pagination > .pagination .page-item:nth-child(1) a::after {
  padding: 0;
  margin: 0;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  font-size: 0.75rem;
  padding-left: 2px;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #2739c1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

ngb-pagination > .pagination .page-item:nth-child(2) a {
  color: transparent;
  position: relative; }

ngb-pagination > .pagination .page-item:nth-child(2) a::after {
  padding: 0;
  margin: 0;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  font-size: 0.75rem;
  padding-left: 2px;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #2739c1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

ngb-pagination > .pagination .page-item:nth-last-child(1) a {
  color: transparent;
  position: relative; }

ngb-pagination > .pagination .page-item:nth-last-child(1) a::after {
  padding: 0;
  margin: 0;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  font-size: 0.75rem;
  padding-left: 2px;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #2739c1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

ngb-pagination > .pagination .page-item:nth-last-child(2) a {
  color: transparent;
  position: relative; }

ngb-pagination > .pagination .page-item:nth-last-child(2) a::after {
  padding: 0;
  margin: 0;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  font-size: 0.75rem;
  padding-left: 2px;
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #2739c1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

ngb-pagination > .pagination .page-item.disabled a::after {
  color: #575962; }
